import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`External Wall Insulation (EWI)`}</h1>
    <p>{`Insulated render systems are a form of Exterior Wall Insulation (EWI) and are a great way to cut your building carbon foot print. Up to 35% of heat lost is through a buildings walls.`}</p>
    <p>{`Some buildings constructed with methods such as single skin walls, solid wall construction, prefabricated concrete panels and steel or timber framed buildings are particularly suited to exterior wall insulation, as they cannot be insulated with cavity wall insulation.`}</p>
    <p>{`Exterior wall insulation is ideal for barn conversions and garage conversions as the insulation is installed to the exterior of the building so it is not taking up valuable space inside the building.`}</p>
    <p>{`There are several types of insulation material available such as phenolic board, polystyrene sheets, compacted mineral wool and wood fibreboards, all of which require a finishing coat applied to them to protect them from the elements.`}</p>
    <p>{`The rendered finishes available for the insulations are `}<a parentName="p" {...{
        "href": "/render-types/polymer-render/",
        "title": "Polymer Render"
      }}>{`polymer render`}</a>{`, `}<a parentName="p" {...{
        "href": "/render-types/lime-render/",
        "title": "Lime Render"
      }}>{`lime render`}</a>{` and thin coat polymer render with an `}<a parentName="p" {...{
        "href": "/render-types/acrylic-render/",
        "title": "Acrylic Render"
      }}>{`acrylic render`}</a>{` finish.`}</p>
    <p>{`The insulation slabs vary in thickness from 20mm to 120mm, each have different U values (unit of insulation). Each insulation slab has to be fixed and bonded with careful consideration and skill, to ensure they are strong enough to withstand the elements. Installing exterior wall insulation needs careful planning and preparation, making sure all roof or eve over hangs are adequate, pipes, vents, extractors, window sills can be extended, as well as wires and services can be accommodated.`}</p>
    <p>{`Suitable substrates: Single skin walls, solid wall construction, barn conversions, garage conversions, timber framed buildings, steel framed buildings, breeze block, light weight thermalite block, brick, stone and prefabricated houses. Other substrate may also be suitable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      